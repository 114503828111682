import React from 'react' ; 

export default function DarkIcon(){
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 64 64"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M42.428 38.203a11.996 11.996 0 01-1.109 1.566.5.5 0 10.767.641c.451-.54.851-1.106 1.202-1.695a.5.5 0 00-.86-.512zm1.411-3.555a12.203 12.203 0 01-.564 1.834.5.5 0 10.93.369c.257-.648.46-1.312.61-1.986a.5.5 0 00-.976-.217zm.234-3.819c.061.638.072 1.279.033 1.919a.5.5 0 10.998.061c.043-.692.031-1.386-.036-2.076a.5.5 0 10-.995.096zm-.961-3.703c.258.59.467 1.196.627 1.813a.5.5 0 00.968-.251c-.173-.668-.4-1.325-.679-1.963a.5.5 0 10-.916.401zm-2.071-3.216c.433.484.821.994 1.164 1.526a.5.5 0 10.84-.542c-.37-.575-.79-1.128-1.26-1.651a.5.5 0 10-.744.667z"
        data-original="#000000"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M32 15c-9.383 0-17 7.617-17 17s7.617 17 17 17 17-7.617 17-17-7.617-17-17-17zm1 2.033c7.813.515 14 7.024 14 14.967s-6.187 14.452-14 14.967zM29.161 5.839v4.598a2.84 2.84 0 005.678 0V5.839a2.84 2.84 0 00-5.678 0zM48.491 11.494l-3.251 3.251c-1.108 1.108-1.108 2.907 0 4.015s2.907 1.108 4.015 0l3.251-3.251c1.108-1.108 1.108-2.907 0-4.015s-2.907-1.108-4.015 0zM58.161 29.161h-4.598a2.84 2.84 0 000 5.678h4.598a2.84 2.84 0 000-5.678zM52.506 48.491l-3.251-3.251c-1.108-1.108-2.907-1.108-4.015 0s-1.108 2.907 0 4.015l3.251 3.251c1.108 1.108 2.907 1.108 4.015 0s1.108-2.907 0-4.015zM34.839 58.161v-4.598a2.84 2.84 0 00-5.678 0v4.598a2.84 2.84 0 005.678 0zM15.509 52.506l3.251-3.251c1.108-1.108 1.108-2.907 0-4.015s-2.907-1.108-4.015 0l-3.251 3.251c-1.108 1.108-1.108 2.907 0 4.015s2.907 1.108 4.015 0zM5.839 34.839h4.598a2.84 2.84 0 000-5.678H5.839a2.84 2.84 0 000 5.678zM11.494 15.509l3.251 3.251c1.108 1.108 2.907 1.108 4.015 0s1.108-2.907 0-4.015l-3.251-3.251c-1.108-1.108-2.907-1.108-4.015 0s-1.108 2.907 0 4.015z"
        data-original="#000000"
      ></path>
    </svg>
    )
}