import React from 'react'

export default function ContactIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 560 560"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M397.668 0H102.293c-34.512.016-62.488 27.988-62.5 62.5v27H10.418c-6.906 0-12.5 5.598-12.5 12.5s5.594 12.5 12.5 12.5h29.246v82H10.418c-6.906 0-12.5 5.594-12.5 12.5 0 6.902 5.594 12.5 12.5 12.5h29.246v82H10.418c-6.906 0-12.5 5.598-12.5 12.5 0 6.906 5.594 12.5 12.5 12.5h29.246v82H10.418c-6.906 0-12.5 5.598-12.5 12.5s5.594 12.5 12.5 12.5h29.246v27c.016 34.512 27.988 62.484 62.5 62.5H397.54c34.512-.016 62.492-27.988 62.5-62.5v-400C460.09 28.012 432.156.023 397.668 0zm37.5 462.5c-.063 20.684-16.816 37.441-37.5 37.5H102.293c-20.684-.059-37.441-16.816-37.5-37.5v-27h29.246c6.906 0 12.5-5.598 12.5-12.5s-5.594-12.5-12.5-12.5H64.793v-82h29.246c6.906 0 12.5-5.594 12.5-12.5 0-6.902-5.594-12.5-12.5-12.5H64.793v-82h29.246c6.906 0 12.5-5.598 12.5-12.5 0-6.906-5.594-12.5-12.5-12.5H64.793v-82h29.246c6.906 0 12.5-5.598 12.5-12.5s-5.594-12.5-12.5-12.5H64.793v-27c.059-20.684 16.816-37.441 37.5-37.5h295.375c20.684.059 37.437 16.816 37.5 37.5zm0 0"
                data-original="#000000"
               
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M249.918 258.875c33.48 0 60.625-27.14 60.625-60.625s-27.145-60.625-60.625-60.625c-33.484 0-60.625 27.14-60.625 60.625.031 33.469 27.152 60.594 60.625 60.625zm0-96.375c19.672 0 35.625 15.953 35.625 35.625s-15.953 35.625-35.625 35.625c-19.676 0-35.625-15.953-35.625-35.625.035-19.656 15.96-35.59 35.625-35.625zm0 0M249.918 279c-52 0-92.754 42.125-92.754 96 .02 6.895 5.606 12.48 12.5 12.5h160.504c6.894-.02 12.48-5.605 12.5-12.5 0-53.875-40.754-96-92.75-96zm-66.754 83.5c5.379-34.125 32.254-58.5 66.754-58.5 34.496 0 61.5 24.375 66.75 58.5zm0 0"
                data-original="#000000"
               
            ></path>
        </svg>
    )
}
