import React from 'react'

export default function FiliereIcon() {
  return (
    <svg
  
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 305 305"
  >
    <g clipPath="url(#clip0_197_1141)">
      <path
        fill="#F1F2F2"
        d="M261.334 149.478c-5.891 14.221-6.262 31.802-.371 46.023l5.069 9.93H47.23s-40.737-22.889 0-63.625l216.913-4.47-2.809 12.142z"
      ></path>
      <path
        fill="#DEE1E1"
        d="M266.033 205.433H47.231s-40.736-22.891 0-63.626l216.914-4.472-2.812 12.145a59.587 59.587 0 00-3.091 9.87H59.697c-7.692 0-13.947 6.254-13.947 13.94 0 7.692 6.255 13.946 13.947 13.946h198.619a53.626 53.626 0 002.647 8.266l5.07 9.931z"
      ></path>
      <path
        fill="#B7E546"
        d="M262.72 214.721l-200.001-.315c-23.961 0-44.539-15.285-44.539-39.246 0-5.292-.921-12.232.814-16.921 6.12-16.541 22.034-28.333 40.703-28.333h205.182c5.405 0 9.786 4.382 9.786 9.786a9.786 9.786 0 01-9.786 9.786H109.592l-15.166 3.56-13.189-3.56h-21.54c-13.152 0-23.814 10.662-23.814 23.813 0 13.152 10.662 23.813 23.813 23.813h205.182a9.786 9.786 0 019.719 10.938c-.57 4.862-6.862 6.679-11.877 6.679z"
      ></path>
      <path
        fill="#ABD641"
        d="M274.595 208.042a9.806 9.806 0 01-2.795 5.768 9.773 9.773 0 01-6.921 2.868l-101.368 6.075-103.814-6.075c-23.964 0-43.388-19.424-43.388-43.388 0-6.249 1.32-12.189 3.701-17.555v8.919c0 23.964 19.424 43.388 43.388 43.388h211.197z"
      ></path>
      <path
        fill="#EF5227"
        d="M109.591 178.008v-28.53H89.257l-7.22.725.433 26.571c0 .869.874 1.465 1.684 1.148l5.102-1.247 5.708-2.235c.29-.113.61-.113.9 0l12.044 4.717a1.234 1.234 0 001.683-1.149z"
      ></path>
      <path
        fill="#D83724"
        d="M89.257 149.48v27.196l-6.335 2.48a1.234 1.234 0 01-1.684-1.147V149.48h8.019z"
      ></path>
      <path
        fill="#FDCD08"
        d="M233.21 104.994v13.91l-.617 6.168a4.319 4.319 0 01-4.317 4.318l-164.392-.617a4.314 4.314 0 01-4.318-4.318l-1.233-19.461 84.39-4.032 90.487 4.032z"
      ></path>
      <path
        fill="#77777F"
        d="M283.859 105.971a4.626 4.626 0 01-4.626-4.626V53.356a4.626 4.626 0 119.253 0v47.988a4.627 4.627 0 01-4.627 4.627z"
      ></path>
      <path
        fill="#FDCD08"
        d="M275.591 116.606c-3.862 9.518-7.747 18.909-11.609 28.428-2.308 5.69 1.838 11.925 7.931 11.925l7.14.617h17.369c6.093 0 10.239-6.235 7.931-11.926L296.3 125.8l-5.065-9.195h-15.644v.001z"
      ></path>
      <path
        fill="#FAAB18"
        d="M281.808 129.309c5.076.462 10.399-.871 14.492-3.508l-4.331-10.822-7.155 2.139v-.002l-9.328-1.244c-1.178 2.905-2.891 7.03-4.069 9.936l-8.05 19.843c-2.307 5.693 1.838 11.923 7.926 11.923h9.87c-6.089 0-10.234-6.23-7.927-11.923 2.006-4.94 4.009-9.879 6.013-14.818.415-1.025 1.458-1.625 2.559-1.524z"
      ></path>
      <path
        fill="#FDCD08"
        d="M298.098 105.769c0-6.304-4.02-11.66-9.614-13.598-1.45-.503-3.558.392-5.176.392-7.864 0-12.704 5.268-12.704 13.206s5.883 12.701 13.747 12.701c1.619 0 2.683 1.399 4.133.897 5.594-1.939 9.614-7.295 9.614-13.598z"
      ></path>
      <path
        fill="#FAAB18"
        d="M288.484 119.367c-1.449.505-3.01.777-4.626.777-7.864 0-14.236-6.44-14.236-14.379 0-7.932 6.372-14.372 14.236-14.372 1.616 0 3.177.272 4.626.778-5.594 1.936-9.61 7.297-9.61 13.594 0 6.305 4.016 11.665 9.61 13.602z"
      ></path>
      <path
        fill="#868491"
        d="M284.999 58.303l-40.686 14.085-11.102-1.14-78.972 32.322a25.886 25.886 0 01-16.936 0l-78.97-31.06-11.103-.122L6.541 58.303c-4.645-1.608-4.645-8.177 0-9.784L138.846 2.718a21.163 21.163 0 0113.849 0l132.304 45.8c4.644 1.608 4.644 8.177 0 9.785z"
      ></path>
      <path
        fill="#77777F"
        d="M244.313 68.213v4.176l-90.076 31.181a25.892 25.892 0 01-16.938 0l-90.07-31.181v-4.176a18.147 18.147 0 0114.953-17.876 475.108 475.108 0 0183.586-7.433c27.93 0 56.058 2.498 83.593 7.433 4.195.759 8 2.967 10.727 6.23a18.181 18.181 0 014.225 11.646z"
      ></path>
      <path
        fill="#868491"
        d="M233.21 68.213v36.781H60.183l-.617-35.547a7.058 7.058 0 015.81-6.946c1.763-.316 2.293-1.855 4.059-2.15a462.793 462.793 0 0176.333-6.344c27.32 0 54.639 2.424 81.632 7.26a7.045 7.045 0 014.163 2.418 7.023 7.023 0 011.647 4.528z"
      ></path>
      <path
        fill="#FAAB18"
        d="M233.21 118.904v6.785a4.319 4.319 0 01-4.318 4.318H62.65a4.318 4.318 0 01-4.317-4.318v-20.695l5.55-4.032 5.552 4.032v10.826a3.084 3.084 0 003.085 3.084h160.69z"
      ></path>
      <path
        fill="#77777F"
        d="M69.435 60.354v44.64H58.333V68.213a7.058 7.058 0 015.81-6.946c1.764-.314 3.528-.623 5.292-.913z"
      ></path>
      <path
        fill="#F1F2F2"
        d="M277.632 282.129a58.044 58.044 0 010-44.421l1.877-8.338H27.28s-28.393 30.868 1.845 61.105l252.027 1.89-3.52-10.236z"
      ></path>
      <path
        fill="#DEE1E1"
        d="M281.151 292.363l-252.024-1.888c-30.243-30.237-1.845-61.103-1.845-61.103l253.44.468-3.086 7.866a57.674 57.674 0 00-2.758 8.413H43.388c-7.692 0-13.947 6.255-13.947 13.941 0 7.691 6.255 13.946 13.947 13.946h231.564a58.566 58.566 0 002.684 8.124l3.515 10.233z"
      ></path>
      <path
        fill="#EF5227"
        d="M276.903 301.287l-228.044-.419c-23.96 0-45.041-13.522-45.041-37.483 0-7.604-1.86-18.075 1.577-24.29 7.393-13.366 21.635-22.418 37.991-22.418h237.802c5.405 0 9.786 4.382 9.786 9.786a9.786 9.786 0 01-9.786 9.786h-58.919l-15.396 3.408-12.958-3.408H43.387c-13.152 0-23.813 10.662-23.813 23.813 0 13.151 10.661 23.813 23.813 23.813h237.801a9.786 9.786 0 019.627 11.554c-.832 4.56-9.111 5.858-13.912 5.858z"
      ></path>
      <path
        fill="#D83724"
        d="M290.817 295.429a9.74 9.74 0 01-2.708 5.15 9.763 9.763 0 01-6.921 2.868h-237.8C19.424 303.447 0 284.023 0 260.06a43.161 43.161 0 016.785-23.304v15.285c0 23.964 19.424 43.388 43.388 43.388h240.644z"
      ></path>
      <path
        fill="#FDCD08"
        d="M222.269 265v-28.76h-19.102l-8.473.516.454 27.01c0 .869.875 1.466 1.683 1.149l6.335-1.73 4.476-1.752c.289-.114.611-.114.899 0l12.044 4.716a1.235 1.235 0 001.684-1.149z"
      ></path>
      <path
        fill="#FAAB18"
        d="M203.167 236.238v26.949l-7.569 2.961a1.235 1.235 0 01-1.684-1.148v-28.762h9.253z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_197_1141">
        <path fill="#fff" d="M0 0H305V305H0z"></path>
      </clipPath>
    </defs>
  </svg>
  )
}
