import React from 'react'

export default function MobileAppIcon({cls}) {
  return (
    <svg className={"animate-pulse w-28"}
     
      xmlns="http://www.w3.org/2000/svg"

      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#816ae2"
          d="M424.316 445.018c47.219-44.66 76.685-107.896 76.685-178.019 0-135.31-109.691-245.001-245.001-245.001S10.999 131.689 10.999 266.999 120.69 512 256 512c10.603 0 21.049-.675 31.297-1.981z"
          data-original="#816ae2"
        ></path>
        <path
          fill="#f9ba8f"
          d="M396.836 365.563a70.542 70.542 0 01-2.24-24.277l4.001-37.685c1.953-23.645-1.651-47.335-12.118-68.626-27.045-55.013-20.841-59.005-21.681-113.604 0-11.153-9.042-20.196-20.196-20.196-11.154 0-20.196 9.042-20.196 20.196 0 222.032.413 205.739-.956 212.249v29.074c0 24.496-19.929 44.426-44.425 44.426H168.473a156.875 156.875 0 0067.183 53.313l.501.21c20.833 8.799 44.094 26.357 47.981 48.142l.376 1.556c54.861-6.36 104.192-30.834 141.86-67.297zM93.463 101.881l-6.151-5.699c-9.483-8.786-24.43-8.216-33.216 1.266-8.786 9.483-8.216 24.43 1.266 33.216l38.101 35.303z"
          data-original="#f9ba8f"
        ></path>
        <path
          fill="#fcad6d"
          d="M67.863 112.997c-5.817-5.39-8.264-13.096-7.271-20.439a23.266 23.266 0 00-6.496 4.89c-8.786 9.483-8.216 24.43 1.266 33.216l38.101 35.303v-29.249z"
          data-original="#fcad6d"
        ></path>
        <path
          fill="#fd995b"
          d="M398.676 370.388c-8.342 57.526-57.852 101.719-117.69 101.719a119.147 119.147 0 01-29.909-3.794c15.843 9.687 30.095 23.845 33.061 40.471l.376 1.556c54.861-6.36 104.191-30.834 141.86-67.297z"
          data-original="#fd995b"
        ></path>
        <path
          fill="#2a428c"
          d="M289.615 408.075H127.38c-19.185 0-34.792-15.608-34.792-34.793V34.793C92.588 15.608 108.195 0 127.38 0h162.235c19.185 0 34.792 15.608 34.792 34.793v338.49c0 19.184-15.608 34.792-34.792 34.792z"
          data-original="#2a428c"
        ></path>
        <path
          fill="#142766"
          d="M127.38 0c-19.185 0-34.792 15.608-34.792 34.792v338.49c0 19.185 15.608 34.793 34.792 34.793h48.364c-10.734-10.95 1.786-21.647 1.786-21.647-17.667-4.635-16.167-23.202-16.167-23.202V61.73c0-17.75 18.03-22.233 18.03-22.233-7.102-7.102 0-14.565 0-14.565V12.907c-6.551-4.152-5.261-9.805-3.835-12.907z"
          data-original="#142766"
        ></path>
        <path
          fill="#3c58a0"
          d="M182.867 24.933h-3.474a6.013 6.013 0 110-12.026h3.474a6.013 6.013 0 110 12.026zM236.771 24.933h-33.382a6.013 6.013 0 110-12.026h33.382a6.013 6.013 0 110 12.026z"
          data-original="#3c58a0"
        ></path>
        <path
          fill="#73c3f9"
          d="M289.615 21.646h-24.383a8.23 8.23 0 00-8.23 8.23c0 5.291-4.329 9.621-9.62 9.621h-77.767c-5.291 0-9.621-4.329-9.621-9.621a8.23 8.23 0 00-8.23-8.23H127.38c-7.26 0-13.146 5.886-13.146 13.146v338.49c0 7.26 5.886 13.146 13.146 13.146h162.235c7.26 0 13.146-5.886 13.146-13.146V34.792c0-7.26-5.886-13.146-13.146-13.146z"
          data-original="#73c3f9"
        ></path>
        <path
          fill="#4fabf7"
          d="M177.529 386.429c-2.5-10.787 10.012-13.307 10.012-13.307s7.958-69.709 0-77.667 0-15.473 0-15.473 8.694-68.972 0-77.667c-8.694-8.694 0-15.473 0-15.473v-77.667c-15.572-9.832-7.381-23.979-7.381-23.979V62.921c-11.767-11.767-.772-23.419-.768-23.423h-9.778c-5.291 0-9.621-4.329-9.621-9.621a8.23 8.23 0 00-8.23-8.23H127.38c-7.26 0-13.146 5.885-13.146 13.146v338.491c0 7.26 5.886 13.146 13.146 13.146h50.149z"
          data-original="#4fabf7"
        ></path>
        <path
          fill="#e9efff"
          d="M159.588 85.195h97.819c6.151 0 11.137-4.986 11.137-11.137s-4.986-11.137-11.137-11.137h-97.819c-6.151 0-11.138 4.986-11.138 11.137 0 6.152 4.987 11.137 11.138 11.137z"
          data-original="#e9efff"
        ></path>
        <path
          fill="#9bd8f9"
          d="M159.588 62.921h20.572v22.275h-20.572c-6.151 0-11.137-4.986-11.137-11.137-.001-6.153 4.986-11.138 11.137-11.138z"
          data-original="#9bd8f9"
        ></path>
        <g fill="#e9efff">
          <path
            d="M277.174 186.841H139.821c-5.51 0-10.018-4.508-10.018-10.018v-57.631c0-5.51 4.508-10.018 10.018-10.018h137.353c5.51 0 10.018 4.508 10.018 10.018v57.631c0 5.51-4.508 10.018-10.018 10.018zM277.174 279.981H139.821c-5.51 0-10.018-4.508-10.018-10.018v-57.631c0-5.51 4.508-10.018 10.018-10.018h137.353c5.51 0 10.018 4.508 10.018 10.018v57.631c0 5.51-4.508 10.018-10.018 10.018zM277.174 373.121H139.821c-5.51 0-10.018-4.508-10.018-10.018v-57.631c0-5.51 4.508-10.018 10.018-10.018h137.353c5.51 0 10.018 4.508 10.018 10.018v57.631c0 5.51-4.508 10.018-10.018 10.018z"
            data-original="#e9efff"
          ></path>
          <path
            d="M277.174 186.841H139.821c-5.51 0-10.018-4.508-10.018-10.018v-57.631c0-5.51 4.508-10.018 10.018-10.018h137.353c5.51 0 10.018 4.508 10.018 10.018v57.631c0 5.51-4.508 10.018-10.018 10.018zM277.174 279.981H139.821c-5.51 0-10.018-4.508-10.018-10.018v-57.631c0-5.51 4.508-10.018 10.018-10.018h137.353c5.51 0 10.018 4.508 10.018 10.018v57.631c0 5.51-4.508 10.018-10.018 10.018zM277.174 373.121H139.821c-5.51 0-10.018-4.508-10.018-10.018v-57.631c0-5.51 4.508-10.018 10.018-10.018h137.353c5.51 0 10.018 4.508 10.018 10.018v57.631c0 5.51-4.508 10.018-10.018 10.018z"
            data-original="#e9efff"
          ></path>
        </g>
        <path
          fill="#d3dcfb"
          d="M177.523 176.823v-57.63c0-5.51 4.508-10.018 10.018-10.018h-47.72c-5.51 0-10.018 4.508-10.018 10.018v57.63c0 5.51 4.508 10.018 10.018 10.018h47.72c-5.51 0-10.018-4.508-10.018-10.018zM177.523 269.963v-57.631c0-5.51 4.508-10.018 10.018-10.018h-47.72c-5.51 0-10.018 4.508-10.018 10.018v57.631c0 5.51 4.508 10.018 10.018 10.018h47.72c-5.51 0-10.018-4.508-10.018-10.018zM177.523 363.103v-57.631c0-5.51 4.508-10.018 10.018-10.018h-47.72c-5.51 0-10.018 4.508-10.018 10.018v57.631c0 5.51 4.508 10.018 10.018 10.018h47.72c-5.51 0-10.018-4.508-10.018-10.018z"
          data-original="#d3dcfb"
        ></path>
        <path
          fill="#3c58a0"
          d="M203.082 141.387h-40.169c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h40.169a7.5 7.5 0 010 15z"
          data-original="#3c58a0"
        ></path>
        <path
          fill="#bec8f7"
          d="M254.082 169.629h-91.169c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h91.169c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM234.415 141.387h-5.667c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h5.667a7.5 7.5 0 017.5 7.5 7.5 7.5 0 01-7.5 7.5z"
          data-original="#bec8f7"
        ></path>
        <path
          fill="#3c58a0"
          d="M203.082 234.527h-40.169c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h40.169c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
          data-original="#3c58a0"
        ></path>
        <path
          fill="#bec8f7"
          d="M254.082 262.769h-91.169c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h91.169a7.5 7.5 0 010 15zM234.415 234.527h-5.667c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h5.667c4.142 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5z"
          data-original="#bec8f7"
        ></path>
        <path
          fill="#3c58a0"
          d="M203.082 327.667h-40.169a7.5 7.5 0 010-15h40.169c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
          data-original="#3c58a0"
        ></path>
        <path
          fill="#bec8f7"
          d="M254.082 355.909h-91.169c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h91.169c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM234.415 327.667h-5.667a7.5 7.5 0 010-15h5.667c4.142 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5z"
          data-original="#bec8f7"
        ></path>
        <path
          fill="#f9ba8f"
          d="M127.669 225.842c-7.731 8.343-20.761 8.84-29.104 1.109l-34.497-31.964c-8.343-7.73-8.84-20.761-1.109-29.104s20.761-8.84 29.104-1.109l34.497 31.964c8.343 7.731 8.84 20.761 1.109 29.104z"
          data-original="#f9ba8f"
        ></path>
        <path
          fill="#fcad6d"
          d="M112.757 212.422L78.26 180.458c-5.77-5.346-7.781-13.226-5.92-20.338a20.468 20.468 0 00-9.381 5.764c-7.731 8.343-7.234 21.374 1.109 29.104l34.497 31.964c8.343 7.73 21.373 7.234 29.104-1.109a20.495 20.495 0 004.81-8.766c-6.707 1.935-14.228.436-19.722-4.655z"
          data-original="#fcad6d"
        ></path>
        <path
          fill="#f9ba8f"
          d="M127.669 338.191c-7.731 8.343-20.761 8.84-29.104 1.109l-34.497-31.964c-8.343-7.73-8.84-20.761-1.109-29.104s20.761-8.84 29.104-1.109l34.497 31.964c8.343 7.73 8.84 20.761 1.109 29.104z"
          data-original="#f9ba8f"
        ></path>
        <path
          fill="#fcad6d"
          d="M112.757 324.77L78.26 292.806c-5.77-5.346-7.781-13.226-5.92-20.338a20.468 20.468 0 00-9.381 5.764c-7.731 8.343-7.234 21.374 1.109 29.104L98.565 339.3c8.343 7.73 21.373 7.234 29.104-1.109a20.495 20.495 0 004.81-8.766c-6.707 1.936-14.228.436-19.722-4.655z"
          data-original="#fcad6d"
        ></path>
        <path
          fill="#f9ba8f"
          d="M127.669 282.017c-7.731 8.343-20.761 8.84-29.104 1.109l-34.497-31.964c-8.343-7.73-8.84-20.761-1.109-29.104s20.761-8.84 29.104-1.109l34.497 31.964c8.343 7.73 8.84 20.76 1.109 29.104z"
          data-original="#f9ba8f"
        ></path>
        <path
          fill="#fcad6d"
          d="M112.757 268.596L78.26 236.632c-5.77-5.346-7.781-13.226-5.92-20.338a20.463 20.463 0 00-9.381 5.764c-7.731 8.343-7.234 21.373 1.109 29.104l34.497 31.964c8.343 7.731 21.373 7.234 29.104-1.109a20.495 20.495 0 004.81-8.766c-6.707 1.936-14.228.436-19.722-4.655z"
          data-original="#fcad6d"
        ></path>
        <path
          fill="#9181f2"
          d="M422.068 86.887c-13.299 16.916-25.301 41.22-15.706 67.742 18 49.753 46.667 91.99 33.333 165.538-6.05 33.372 13.803 47.361 37.296 52.687C492.37 340.812 501 304.916 501 266.999c.001-71.228-30.405-135.345-78.932-180.112z"
          data-original="#9181f2"
        ></path>
      </g>
    </svg>
  )
}
