import React from 'react'

export default function CalanderIcon({w,h}) {
    return (
        <svg
            width={w}
            height={h}
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#bababa"
                d="M453.319 85.089c0-18.661-15.129-33.788-33.79-33.788H33.789C15.13 51.301 0 66.429 0 85.089v385.738c0 9.658 4.059 18.362 10.556 24.52h408.973v9.269h59.186c14.542 0 26.938-9.188 31.702-22.076 3.948-10.435 5.344 9.516-57.098-397.451z"
                data-original="#bababa"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#a8a8a8"
                d="M473.952 470.828v-251.26L453.319 85.089c0-18.661-15.129-33.788-33.79-33.788H54.424c-18.659 0-33.789 15.127-33.789 33.788v385.738c0 9.658 4.059 18.362 10.556 24.52h388.34v9.269h20.633c18.659.001 33.788-15.126 33.788-33.788z"
                data-original="#a8a8a8"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#e6e6e6"
                d="M419.529 51.301H33.789C15.13 51.301 0 66.429 0 85.089v385.738c0 18.662 15.13 33.789 33.789 33.789h385.739c18.661 0 33.79-15.127 33.79-33.789V85.089c.001-18.66-15.129-33.788-33.789-33.788z"
                data-original="#e6e6e6"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#cccbca"
                d="M20.633 470.828V85.089c0-18.661 15.13-33.788 33.789-33.788H33.789C15.13 51.301 0 66.429 0 85.089v385.738c0 18.662 15.13 33.789 33.789 33.789h20.633c-18.658.001-33.789-15.126-33.789-33.788z"
                data-original="#cccbca"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#ea473b"
                d="M419.529 51.301H33.789C15.13 51.301 0 66.429 0 85.089v86.796h453.319V85.089c0-18.66-15.13-33.788-33.79-33.788z"
                data-original="#ea473b"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#d63322"
                d="M20.633 85.089c0-18.661 15.13-33.788 33.789-33.788H33.789C15.13 51.301 0 66.429 0 85.089v86.796h20.633V85.089z"
                data-original="#d63322"
            ></path>
            <g xmlns="http://www.w3.org/2000/svg" fill="#414356">
                <path
                    d="M361.837 81.509v7.034c0 6.838-5.542 7.222-12.38 7.222h-4.127c-6.837 0-12.38-.384-12.38-7.222V81.51c-6.546 4.568-10.833 12.15-10.833 20.738 0 13.96 11.317 25.276 25.276 25.276 24.757-.001 34.591-31.955 14.444-46.015zM233.91 81.509v7.034c0 6.838-5.542 6.19-12.38 6.19h-4.127c-6.837 0-12.38.648-12.38-6.19V81.51c-6.546 4.568-10.833 12.15-10.833 20.738 0 13.96 11.317 25.276 25.276 25.276 13.96 0 25.276-11.316 25.276-25.276 0-8.588-4.287-16.171-10.832-20.739zM105.983 81.509v7.034c0 7.972-7.503 7.222-16.507 7.222-6.837 0-12.38-.384-12.38-7.222V81.51c-20.167 14.074-10.282 46.014 14.443 46.014 13.96 0 25.276-11.316 25.276-25.276 0-8.588-4.287-16.171-10.832-20.739z"
                    data-original="#414356"
                ></path>
            </g>
            <g xmlns="http://www.w3.org/2000/svg" fill="#2f3242">
                <path
                    d="M343.783 102.248c0-.479.018-.953.044-1.425-6.127-.743-10.877-5.952-10.877-12.28V81.51c-6.546 4.568-10.833 12.15-10.833 20.738 0 13.96 11.317 25.276 25.276 25.276 3.877 0 7.549-.875 10.833-2.436-8.539-4.057-14.443-12.758-14.443-22.84zM215.856 102.248c0-.479.018-.953.044-1.425-6.127-.743-10.877-5.952-10.877-12.28V81.51c-6.546 4.568-10.833 12.15-10.833 20.738 0 13.96 11.317 25.276 25.276 25.276 3.877 0 7.549-.875 10.833-2.436-8.539-4.057-14.443-12.758-14.443-22.84zM87.973 100.823c-6.127-.743-10.877-5.952-10.877-12.28V81.51c-6.546 4.568-10.833 12.15-10.833 20.738 0 18.616 19.444 30.759 36.108 22.84-9.054-4.303-14.984-13.708-14.398-24.265z"
                    data-original="#2f3242"
                ></path>
            </g>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#f77c79"
                d="M0 171.881H453.32V200.768H0z"
                data-original="#f77c79"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#dd6464"
                d="M0 171.881H20.633V200.768H0z"
                data-original="#dd6464"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#585a60"
                d="M93.603 7.385c6.838 0 12.38 5.543 12.38 12.38v68.778c0 6.838-5.542 12.38-12.38 12.38h-4.127c-6.837 0-12.38-5.542-12.38-12.38V19.765c0-6.837 5.543-12.38 12.38-12.38h4.127z"
                data-original="#585a60"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#585a60"
                d="M221.53 7.385c6.838 0 12.38 5.543 12.38 12.38v68.778c0 6.838-5.542 12.38-12.38 12.38h-4.127c-6.837 0-12.38-5.542-12.38-12.38V19.765c0-6.837 5.543-12.38 12.38-12.38h4.127z"
                data-original="#585a60"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#585a60"
                d="M349.457 7.385c6.838 0 12.38 5.543 12.38 12.38v68.778c0 6.838-5.542 12.38-12.38 12.38h-4.127c-6.837 0-12.38-5.542-12.38-12.38V19.765c0-6.837 5.543-12.38 12.38-12.38h4.127z"
                data-original="#585a60"
            ></path>
            <g xmlns="http://www.w3.org/2000/svg">
                <path
                    fill="#585a60"
                    d="M93.603 7.385c6.838 0 12.38 5.543 12.38 12.38v68.778c0 6.838-5.542 12.38-12.38 12.38h-4.127c-6.837 0-12.38-5.542-12.38-12.38V19.765c0-6.837 5.543-12.38 12.38-12.38h4.127z"
                    data-original="#585a60"
                ></path>
            </g>
            <g xmlns="http://www.w3.org/2000/svg">
                <path
                    fill="#585a60"
                    d="M221.53 7.385c6.838 0 12.38 5.543 12.38 12.38v68.778c0 6.838-5.542 12.38-12.38 12.38h-4.127c-6.837 0-12.38-5.542-12.38-12.38V19.765c0-6.837 5.543-12.38 12.38-12.38h4.127z"
                    data-original="#585a60"
                ></path>
            </g>
            <g xmlns="http://www.w3.org/2000/svg">
                <path
                    fill="#585a60"
                    d="M349.457 7.385c6.838 0 12.38 5.543 12.38 12.38v68.778c0 6.838-5.542 12.38-12.38 12.38h-4.127c-6.837 0-12.38-5.542-12.38-12.38V19.765c0-6.837 5.543-12.38 12.38-12.38h4.127z"
                    data-original="#585a60"
                ></path>
            </g>
            <g xmlns="http://www.w3.org/2000/svg" fill="#414356">
                <path
                    d="M91.539 88.543V19.765c0-4.994 2.964-9.286 7.222-11.244a12.32 12.32 0 00-5.158-1.136h-4.127c-6.837 0-12.38 5.543-12.38 12.38v68.778c0 6.838 5.543 12.38 12.38 12.38h4.127c1.844 0 3.587-.414 5.158-1.136-4.258-1.957-7.222-6.25-7.222-11.244zM219.466 88.543V19.765c0-4.994 2.964-9.286 7.222-11.244a12.32 12.32 0 00-5.158-1.136h-4.127c-6.837 0-12.38 5.543-12.38 12.38v68.778c0 6.838 5.543 12.38 12.38 12.38h4.127c1.844 0 3.587-.414 5.158-1.136-4.258-1.957-7.222-6.25-7.222-11.244zM347.393 88.543V19.765c0-4.994 2.964-9.286 7.222-11.244a12.32 12.32 0 00-5.158-1.136h-4.127c-6.837 0-12.38 5.543-12.38 12.38v68.778c0 6.838 5.543 12.38 12.38 12.38h4.127c1.844 0 3.587-.414 5.158-1.136-4.258-1.957-7.222-6.25-7.222-11.244z"
                    data-original="#414356"
                ></path>
            </g>
            <g xmlns="http://www.w3.org/2000/svg" fill="#585a60">
                <path
                    d="M168.606 248.179h-25.474a7.738 7.738 0 000 15.476h25.474a7.738 7.738 0 000-15.476zM239.427 248.179H213.95a7.738 7.738 0 000 15.476h25.477a7.739 7.739 0 000-15.476zM310.247 248.179h-25.478a7.738 7.738 0 000 15.476h25.478a7.738 7.738 0 000-15.476zM381.065 248.179h-25.477a7.738 7.738 0 000 15.476h25.477a7.739 7.739 0 000-15.476zM97.789 310.519H72.312a7.738 7.738 0 000 15.476h25.477a7.738 7.738 0 000-15.476zM168.606 310.519h-25.474a7.738 7.738 0 000 15.476h25.474a7.737 7.737 0 007.738-7.738 7.737 7.737 0 00-7.738-7.738zM239.427 310.519H213.95a7.738 7.738 0 000 15.476h25.477a7.738 7.738 0 000-15.476zM310.247 310.519h-25.478a7.738 7.738 0 000 15.476h25.478a7.738 7.738 0 000-15.476zM381.065 310.519h-25.477a7.738 7.738 0 000 15.476h25.477a7.738 7.738 0 000-15.476zM97.789 372.859H72.312a7.738 7.738 0 000 15.476h25.477c4.274 0 7.738-3.464 7.738-7.738s-3.464-7.738-7.738-7.738zM168.606 372.859h-25.474a7.738 7.738 0 000 15.476h25.474c4.274 0 7.738-3.464 7.738-7.738s-3.464-7.738-7.738-7.738zM239.427 372.859H213.95a7.738 7.738 0 000 15.476h25.477c4.274 0 7.738-3.464 7.738-7.738s-3.464-7.738-7.738-7.738zM310.247 372.859h-25.478a7.738 7.738 0 000 15.476h25.478a7.738 7.738 0 000-15.476zM381.065 372.859h-25.477a7.738 7.738 0 000 15.476h25.477c4.274 0 7.738-3.464 7.738-7.738s-3.464-7.738-7.738-7.738zM97.789 435.2H72.312a7.738 7.738 0 000 15.476h25.477a7.738 7.738 0 000-15.476zM168.606 435.2h-25.474a7.738 7.738 0 000 15.476h25.474a7.737 7.737 0 007.738-7.738 7.737 7.737 0 00-7.738-7.738zM239.427 435.2H213.95a7.738 7.738 0 000 15.476h25.477a7.738 7.738 0 000-15.476zM310.247 435.2h-25.478a7.738 7.738 0 000 15.476h25.478a7.738 7.738 0 000-15.476z"
                    data-original="#585a60"
                ></path>
            </g>
        </svg>
    )
}
