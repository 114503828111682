import React from 'react'

export default function UiUxIcon({cls}) {
  return (
    <svg className={'icon-competence'}
      xmlns="http://www.w3.org/2000/svg"
     
      enableBackground="new 0 0 512 512"
      viewBox="0 0 6.827 6.827"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <g >
          <path
            d="M.819.684a.332.332 0 00-.332.332V4.38c0 .183.149.331.332.331h5.189a.332.332 0 00.332-.331V1.016a.332.332 0 00-.332-.332H.818zm5.189 4.168H.818a.472.472 0 01-.471-.472V1.016c0-.26.211-.472.472-.472h5.189c.26 0 .472.211.472.472V4.38c0 .26-.212.472-.472.472z"
            data-original="#000000"
          ></path>
          <path
            d="M6.008 1.019zM.822 4.377h5.183V1.019H.822zm5.186.14H.818a.138.138 0 01-.137-.137V1.016C.681.94.743.878.82.878h5.189c.076 0 .138.062.138.138V4.38a.138.138 0 01-.138.137z"
            data-original="#000000"
          ></path>
          <path
            d="M3.781 5.965h-.736a.07.07 0 01-.07-.07V4.782a.07.07 0 01.14 0v1.042h.596V4.782a.07.07 0 01.14 0v1.113a.07.07 0 01-.07.07z"
            data-original="#000000"
          ></path>
          <path
            d="M1.976 6.283a.07.07 0 01-.054-.025l-.276-.324a.07.07 0 01.033-.113l1.346-.406a.07.07 0 01.04.134l-1.243.376.178.207 1.025-.305a.07.07 0 11.04.135l-1.069.318a.072.072 0 01-.02.003z"
            data-original="#000000"
          ></path>
          <path
            d="M4.85 6.283a.071.071 0 01-.02-.003l-1.069-.318a.07.07 0 11.04-.135l1.026.305.177-.207-1.243-.376a.07.07 0 11.04-.134l1.347.406a.07.07 0 01.033.113l-.277.324a.07.07 0 01-.053.025z"
            data-original="#000000"
          ></path>
          <path
            d="M1.583 3.301a.472.472 0 01-.472-.471v-.665a.07.07 0 01.14 0v.665a.331.331 0 00.663 0v-.665a.07.07 0 01.14 0v.665a.47.47 0 01-.471.471z"
            data-original="#000000"
          ></path>
          <path
            d="M2.936 3.301a.07.07 0 01-.06-.034l-.65-1.065a.07.07 0 11.12-.074l.65 1.066a.07.07 0 01-.06.107z"
            data-original="#000000"
          ></path>
          <path
            d="M2.287 3.301a.07.07 0 01-.06-.107l.649-1.066a.07.07 0 01.12.074l-.65 1.065a.07.07 0 01-.06.034z"
            data-original="#000000"
          ></path>
          <path
            d="M5.378 3.301a.07.07 0 01-.07-.07V2.164a.07.07 0 01.14 0v1.067a.07.07 0 01-.07.07z"
            data-original="#000000"
          ></path>
          <path
            d="M5.645 2.235H5.11a.07.07 0 010-.14h.535a.07.07 0 010 .14z"
            data-original="#000000"
          ></path>
          <path
            d="M5.645 3.301H5.11a.07.07 0 010-.14h.535a.07.07 0 010 .14z"
            data-original="#000000"
          ></path>
          <path
            d="M4.375 3.301a.472.472 0 01-.47-.471v-.665a.07.07 0 01.14 0v.665a.331.331 0 00.661 0v-.665a.07.07 0 01.14 0v.665a.47.47 0 01-.47.471z"
            data-original="#000000"
          ></path>
          <path
            d="M3.178 3.648l-.019-.003a.07.07 0 01-.05-.086l.472-1.76a.07.07 0 01.136.037l-.471 1.76a.07.07 0 01-.068.052z"
            data-original="#000000"
          ></path>
        </g>
      </g>
    </svg>
  )
}
