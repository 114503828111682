import React from 'react'

export default function MysqlIcon({ cls }) {
    return (
        <svg
            className={cls}
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#f2f2f2"
                d="M512 256c0 36.937-7.826 72.056-21.911 103.769a250.694 250.694 0 01-7.387 15.266C439.85 456.474 354.408 512 256 512c-103.685 0-192.972-61.639-233.221-150.277a253.588 253.588 0 01-16.394-48.661C2.205 294.713 0 275.613 0 256 0 114.615 114.615 0 256 0c115.012 0 212.302 75.839 244.611 180.245a266.141 266.141 0 012.884 10.073v.01a255.202 255.202 0 014.305 19.247c.554 3.03 1.055 6.071 1.494 9.132.303 2.048.585 4.096.825 6.165.46 3.699.825 7.429 1.118 11.18.502 6.584.763 13.24.763 19.948z"
                data-original="#f2f2f2"
            ></path>
            <g xmlns="http://www.w3.org/2000/svg" fill="#5181a2">
                <path
                    d="M367.961 77.614s7.42 7.632 8.691 13.355c0 0 5.3-4.028 1.696-10.387-3.604-6.36-10.387-2.968-10.387-2.968z"
                    data-original="#5181a2"
                ></path>
                <path
                    d="M503.495 190.318c-6.666-3.354-12.727-5.339-17.471-6.51-6.416-1.588-11.609-6.311-13.855-12.528-2.884-7.973-8.255-20.888-18.139-40.458-19.508-38.578-41.127-50.448-55.327-60.416-9.32-6.541-21.567-7.868-28.881-8.014a16.202 16.202 0 01-10.073-3.793c-7.45-6.269-22.1-13.636-26.76-10.877-5.726 3.396 0 11.243 8.046 21.201 8.056 9.968 9.759 20.992 15.475 35.83 2.644 6.844 5.831 10.982 8.485 13.448 2.779 2.591 3.688 6.614 2.215 10.104-5.371 12.696-7.168 28.776-4.127 43.645 3.605 17.596 11.87 17.387 11.87 17.387-.846-23.531 11.452-33.708 11.452-33.708 11.233 34.335 36.885 59.988 36.885 59.988-20.773-7.419-36.247-41.336-36.247-41.336s-1.693 1.693-3.177 12.936c-1.484 11.233-15.057 18.014-25.015-6.572-9.968-24.597 2.967-55.547 2.967-55.547-5.726-2.968-17.596-30.312-17.596-34.555 0-4.232-4.671-13.563-10.386-19.289-5.726-5.716-16.959-22.256-3.814-29.884 13.134-7.638 39.215 12.925 39.215 12.925 41.127-1.484 83.519 48.546 90.3 66.351 6.781 17.805 25.861 53.426 25.861 53.426 5.413 1.641 10.501 3.762 15.214 6.175a266.828 266.828 0 012.883 10.071zM511.237 236.053c-.209-.094-.408-.199-.606-.293-13.145-6.363-18.442-19.717-18.442-19.717 3.406-3.406 9.446-5.36 15.611-6.468.554 3.03 1.055 6.071 1.494 9.132-2.299.449-3.751.721-3.751.721 0 1.296 1.808 3.229 4.577 5.444.459 3.699.825 7.43 1.117 11.181z"
                    data-original="#5181a2"
                ></path>
            </g>
            <g xmlns="http://www.w3.org/2000/svg" fill="#ffa600">
                <path
                    d="M133.371 374.659H113.34s0-45.777-6.677-100.153L75.18 374.659H58.964l-31.953-99.673s-3.72 29.288-4.232 86.737a253.588 253.588 0 01-16.394-48.661c1.473-21.264 3.542-44.44 6.322-63.352h26.07l29.727 92.526 31.483-92.526h23.845c-.001 0 9.539 81.554 9.539 124.949zM144.423 283.452l20.668-.318s23.212 69.637 20.987 78.222c0 0 14.945-41.655 18.761-78.222h20.35s-10.812 73.771-43.88 112.564c-12.375 14.517-26.262 14.695-38.799 9.236v-11.143s17.177 6.042 26.081-6.678c7.168-10.24.322-27.898-5.938-46.671-1.518-4.546-18.23-56.99-18.23-56.99z"
                    data-original="#ffa600"
                ></path>
            </g>
            <g xmlns="http://www.w3.org/2000/svg" fill="#5181a2">
                <path
                    d="M237.908 365.491l5.405-10.812s37.204 16.534 46.742 0c9.539-16.534.318-25.438-18.442-35.296s-38.475-22.258-29.89-49.287c8.586-27.028 54.056-25.12 67.093-12.083l-4.451 10.493s-33.705-14.309-42.926 4.451c-9.221 18.76 15.263 27.664 20.987 30.208 5.724 2.544 37.521 15.58 34.024 38.793s-17.807 37.521-50.558 34.659c-11.768-2.859-18.445-4.767-27.984-11.126zM405.452 367c14.054-11.295 20.083-31.389 20.083-54.293 0-35.328-10.167-63.969-50.333-63.969-34.586 0-50.333 28.641-50.333 63.969s11.515 63.969 50.333 63.969c5.622 0 10.721-.7 15.318-2.017l29.905 17.544 7.314-14.315L405.452 367zm-30.25-5.643c-22.507 0-29.184-21.786-29.184-48.65 0-26.875 6.363-48.65 29.184-48.65 23.28 0 29.174 21.776 29.174 48.65-.001 26.864-5.894 48.65-29.174 48.65zM490.088 359.769a250.694 250.694 0 01-7.387 15.266h-39.382V250.378h19.717v109.39h27.052z"
                    data-original="#5181a2"
                ></path>
            </g>
        </svg>
    )
}
