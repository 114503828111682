import React from 'react'

export default function GithubIcon({w,h}) {
    return (
        <svg
            className='animate-contact'
            xmlns="http://www.w3.org/2000/svg"
            width={w}
            height={h}
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#384949"
                d="M512 257c0 120-84.102 220.5-196 247.5l-30.602-97.2h-58.796L197 504.5C85.102 477.5 0 377 0 257 0 116.3 115.3 0 256 0s256 116.3 256 257zm0 0"
                data-original="#384949"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#293939"
                d="M512 257c0 120-84.102 220.5-196 247.5l-30.602-97.2H256V0c140.7 0 256 116.3 256 257zm0 0"
                data-original="#293939"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#ececf1"
                d="M181.277 430.059a62.818 62.818 0 01-17.828-2.579c-15.129-4.468-27.422-14.546-36.547-29.914-4.16-7.015-8.496-11.878-13.605-15.308-5.027-3.383-9.04-4.672-13.274-4.363l-2.636-29.883c11.117-.953 21.754 2.062 32.593 9.316 8.833 5.902 16.258 14.063 22.72 24.914 5.304 8.922 11.41 14.153 19.25 16.469 8.804 2.59 17.94 1.508 29.632-3.473l11.809 27.567c-11.297 4.836-21.93 7.254-32.114 7.254zm0 0"
                data-original="#ececf1"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#ececf1"
                d="M400.902 287.3c-10.504 27.9-36.902 63.302-103.8 73.2C305.8 373.398 316.3 380.3 316 407.3v97.2c-19.2 4.8-39.3 7.5-60 7.5s-39.8-2.7-59-7.5v-98.402c0-26.7 10.102-34.2 17.898-45.598-66.898-9.902-93.296-45.3-103.8-73.2C97 250.099 104.5 203.899 129.102 174.5c.597-.602 1.5-2.102 1.199-3-11.403-34.2 2.398-62.7 3-65.7 12.898 3.9 15-3.902 56.699 21.598l7.2 4.204c3 1.796 2.1.597 5.1.597 17.4-4.8 35.7-7.5 53.7-7.5 18.3 0 36.3 2.7 54.598 7.5l2.101.301s.598 0 2.102-.898c51.898-31.504 50.097-21.301 64.195-25.801.3 3 14.102 31.797 2.703 65.699-1.5 4.5 45 47.098 19.203 115.8zm0 0"
                data-original="#ececf1"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#e2e2e7"
                d="M400.902 287.3c-10.504 27.9-36.902 63.302-103.8 73.2C305.8 373.398 316.3 380.3 316 407.3v97.2c-19.2 4.8-39.3 7.5-60 7.5V124.7c18.3 0 36.3 2.698 54.602 7.5l2.097.3s.602 0 2.102-.898C366.699 100.098 364.898 110.3 379 105.8c.3 3 14.102 31.797 2.7 65.699-1.5 4.5 45 47.098 19.202 115.8zm0 0"
                data-original="#e2e2e7"
            ></path>
        </svg>
    )
}
