import React from 'react';

const CompetenceIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M326.451 257.092l-33.464-13.014a8.478 8.478 0 01-5.44-7.952V219.43c9.825-8.723 16.032-21.435 16.032-35.575v-3.151c5.248-7.414 8.016-15.967 8.016-24.905 0-24.026-19.546-43.573-43.572-43.573h-24.049c-24.026 0-43.572 19.547-43.572 43.573 0 8.938 2.768 17.491 8.016 24.905v3.151h.001c0 14.141 6.207 26.853 16.032 35.575v16.696a8.479 8.479 0 01-5.44 7.952l-33.464 13.014c-15.323 5.958-25.225 20.433-25.225 36.875v66.243a7.5 7.5 0 0015 0v-66.243c0-10.208 6.147-19.195 15.661-22.894l22.928-8.917c8.149 15.403 24.36 25.392 42.088 25.392 17.841 0 33.964-9.956 42.098-25.388l22.918 8.913c9.514 3.699 15.661 12.686 15.661 22.894v66.243a7.5 7.5 0 0015 0v-66.243c0-16.442-9.901-30.916-25.225-36.875zm-103.032-73.236v-16.032a7.5 7.5 0 00-7.5-7.5c-.045 0-.088.006-.133.007a27.462 27.462 0 01-.383-4.531c0-15.757 12.818-28.574 28.573-28.574h24.049c15.755 0 28.572 12.818 28.572 28.573 0 1.529-.134 3.041-.383 4.531-.037-.001-.074-.006-.111-.006-.226-.001-22.912-.306-52.781-15.24a7.5 7.5 0 00-10.062 3.354 7.499 7.499 0 003.354 10.062c22.915 11.458 41.978 15.113 51.967 16.279v9.077c0 17.965-14.616 32.581-32.581 32.581-17.965 0-32.581-14.616-32.581-32.581zM256 272.548c-11.658 0-22.347-6.315-28.121-16.155 7.132-4.178 11.573-11.779 11.573-20.267v-7.664c5.157 1.919 10.731 2.973 16.548 2.973s11.391-1.054 16.548-2.973v7.664c0 8.489 4.442 16.09 11.574 20.268-5.759 9.855-16.389 16.154-28.122 16.154z"
                data-original="#000000"
               
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M199.887 304.613a7.5 7.5 0 00-7.5 7.5v72.146a7.5 7.5 0 0015 0v-72.146a7.5 7.5 0 00-7.5-7.5zM312.113 304.612a7.5 7.5 0 00-7.5 7.5v72.146a7.5 7.5 0 0015 0v-72.146a7.5 7.5 0 00-7.5-7.5zM256 0c-21.816 0-39.564 17.749-39.564 39.564S234.184 79.129 256 79.129s39.564-17.749 39.564-39.564S277.816 0 256 0zm0 64.129c-13.545 0-24.564-11.02-24.564-24.564S242.455 15 256 15s24.564 11.02 24.564 24.564S269.545 64.129 256 64.129zM256 432.871c-21.816 0-39.564 17.749-39.564 39.564S234.184 512 256 512s39.564-17.749 39.564-39.564-17.748-39.565-39.564-39.565zM256 497c-13.545 0-24.564-11.02-24.564-24.564s11.02-24.564 24.564-24.564 24.564 11.02 24.564 24.564S269.545 497 256 497zM472.436 216.436c-21.816 0-39.564 17.749-39.564 39.564s17.749 39.564 39.564 39.564S512 277.816 512 256s-17.749-39.564-39.564-39.564zm0 64.128c-13.545 0-24.564-11.02-24.564-24.564s11.02-24.564 24.564-24.564S497 242.455 497 256s-11.02 24.564-24.564 24.564zM39.565 216.436C17.75 216.436 0 234.184 0 256s17.749 39.564 39.564 39.564S79.129 277.816 79.129 256s-17.749-39.564-39.564-39.564zm-.001 64.128C26.02 280.564 15 269.545 15 256s11.02-24.564 24.564-24.564S64.129 242.455 64.129 256s-11.02 24.564-24.565 24.564zM469.936 189.652A223.766 223.766 0 00322.349 42.065a7.5 7.5 0 00-9.383 4.945 7.498 7.498 0 004.944 9.383A208.771 208.771 0 01455.607 194.09a7.503 7.503 0 007.162 5.283 7.5 7.5 0 007.167-9.721zM199.035 47.01a7.502 7.502 0 00-9.383-4.945A223.766 223.766 0 0042.065 189.652a7.5 7.5 0 007.167 9.721c3.2 0 6.165-2.065 7.162-5.283A208.77 208.77 0 01194.09 56.393a7.5 7.5 0 004.945-9.383zM194.09 455.607A208.773 208.773 0 0156.393 317.91a7.5 7.5 0 00-14.328 4.438 223.766 223.766 0 00147.587 147.587 7.494 7.494 0 002.222.338 7.5 7.5 0 002.216-14.666zM464.99 312.965a7.5 7.5 0 00-9.383 4.945A208.771 208.771 0 01317.91 455.607a7.5 7.5 0 004.439 14.328 223.766 223.766 0 00147.587-147.587 7.5 7.5 0 00-4.946-9.383z"
                data-original="#000000"
               
            ></path>
        </svg>
    );
}

export default CompetenceIcon;
