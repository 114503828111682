import React from 'react'

export default function ReactNativeIcon({cls}) {
    return (
        <svg
            className={cls}
            xmlns="http://www.w3.org/2000/svg"    
            enableBackground="new 0 0 512 512"
            viewBox="0 0 24 24"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#0097a7"
                d="M18.697 19.487c-2.684 0-6.246-1.221-9.338-2.97-4.18-2.365-7.309-5.489-7.971-7.959-.31-1.156-.04-1.968.242-2.445 1.718-2.918 7.986-1.472 13.01 1.371 4.18 2.365 7.309 5.489 7.971 7.959.31 1.156.04 1.968-.242 2.445-.661 1.122-1.994 1.599-3.672 1.599zM5.299 6.498c-1.959 0-2.208.686-1.979 1.542.42 1.568 2.797 4.345 7.023 6.737 5.283 2.989 9.631 3.238 10.302 2.096.126-.214.138-.521.033-.912-.42-1.568-2.797-4.345-7.023-6.737-3.428-1.94-6.464-2.726-8.356-2.726z"
                data-original="#0097a7"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#607d8b"
                d="M14 12a2 2 0 11-4.001-.001A2 2 0 0114 12z"
                data-original="#607d8b"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#00bcd4"
                d="M12 24c-3.283 0-5-6.037-5-12S8.717 0 12 0s5 6.037 5 12-1.717 12-5 12zm0-22c-1.194 0-3 3.988-3 10s1.806 10 3 10 3-3.988 3-10-1.806-10-3-10z"
                data-original="#00bcd4"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#4dd0e1"
                d="M14.14 5.36c-.71.22-1.43.5-2.14.81.82.35 1.63.75 2.42 1.19l.18-.06c-.13-.71-.29-1.36-.46-1.94zm8.23.75c-.98-1.66-3.43-1.91-6.29-1.28.18.61.33 1.26.45 1.93.83-.18 1.56-.26 2.17-.26 2.014 0 2.187.732 1.98 1.54-.18.67-.72 1.57-1.61 2.57-1.804 1.98-4.587 3.881-7.07 5.03.85.41 1.66.74 2.42 1 1.65-.944 4.001-2.374 6.07-4.64 1.09-1.19 1.84-2.37 2.12-3.44.231-.853.193-1.697-.24-2.45zM14.6 7.3c-.13-.71-.29-1.36-.46-1.94-.71.22-1.43.5-2.14.81.82.35 1.63.75 2.42 1.19zm-5.2 9.4c.13.71.29 1.37.46 1.95.71-.23 1.43-.5 2.14-.81-.82-.36-1.64-.76-2.42-1.2zM6.36 12A25.135 25.135 0 0112 8.36c-.85-.41-1.66-.74-2.42-1-1.65.944-3.127 1.887-4.65 3.25-1.366 1.186-3.079 3.067-3.54 4.83-.576 2.153.636 4.05 3.91 4.05.81 0 1.69-.11 2.62-.32-.18-.61-.33-1.26-.45-1.93-1.88.4-4.63.593-4.15-1.28.382-1.423 1.945-2.987 3.04-3.96z"
                data-original="#4dd0e1"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#008391"
                d="M9.069 14.009c.063.901.167 1.742.301 2.514l.21.117h.001c.786.435 1.599.835 2.419 1.19v-2.189a27.378 27.378 0 01-2.931-1.632zm-2.705-2.012L6.36 12c-.436.388-.947.87-1.428 1.399.672.593 1.42 1.178 2.228 1.739a30.16 30.16 0 01-.156-2.606 20.536 20.536 0 01-.64-.535zm3.494-6.642a18.656 18.656 0 00-.455 1.94A22.727 22.727 0 0112 8.359V6.17a24.955 24.955 0 00-2.142-.815zm-4.556-.842c-1.677 0-3.011.477-3.672 1.599-.281.477-.551 1.289-.241 2.445.285 1.064 1.028 2.25 2.116 3.444.474-.521.969-.995 1.426-1.391l.007-.006C4.043 9.61 3.501 8.713 3.321 8.04c-.23-.855.02-1.542 1.979-1.542.611 0 1.341.082 2.164.258.126-.675.278-1.322.455-1.932-.927-.201-1.811-.311-2.617-.311z"
                data-original="#008391"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#546d79"
                d="M12 10a2 2 0 000 4z"
                data-original="#546d79"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#00a4b9"
                d="M9.07 9.983c-.703.454-1.398.951-2.065 1.484a31.57 31.57 0 000 1.065c.015.877.067 1.752.156 2.606.075.721.176 1.426.303 2.104l.006-.001c.12.67.271 1.32.45 1.93h-.002C8.741 22.003 10.112 24 12 24v-2c-.769 0-1.792-1.654-2.422-4.437A18.707 18.707 0 019.4 16.7h.002a24.882 24.882 0 01-.332-2.691 28.893 28.893 0 010-4.026zM12 0c-1.887 0-3.257 1.995-4.081 4.824-.177.61-.329 1.257-.455 1.932a26.157 26.157 0 00-.303 2.104A32.425 32.425 0 019.37 7.481a18.916 18.916 0 01.488-2.126C10.484 3.231 11.337 2 12 2z"
                data-original="#00a4b9"
            ></path>
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#43b5c4"
                d="M9.58 16.64l-.178.06H9.4a20.132 20.132 0 00.46 1.95c.71-.23 1.43-.5 2.14-.81-.82-.36-1.64-.76-2.42-1.2zm0-9.28c-.07.04-.141.08-.21.121-.765.441-1.494.887-2.209 1.379a21.953 21.953 0 00-2.223 1.744l-.007.006c-.456.396-.951.87-1.426 1.391-.946 1.039-1.808 2.265-2.115 3.439-.576 2.153.636 4.05 3.91 4.05.809 0 1.689-.11 2.618-.32h.002c-.18-.61-.33-1.26-.45-1.93l-.006.001c-.686.146-1.488.264-2.205.264-1.248 0-2.243-.357-1.938-1.545.23-.856.887-1.762 1.612-2.561.48-.529.99-1.011 1.427-1.399l.004-.003A25.57 25.57 0 019.07 9.983 24.164 24.164 0 0112 8.36a23.826 23.826 0 00-1.445-.638 20.177 20.177 0 00-.975-.362z"
                data-original="#43b5c4"
            ></path>
        </svg>
    )
}
