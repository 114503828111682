import React from 'react'

export default function Python({cls}) {
  return (
    <svg
    className={cls}
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 512 512"
    viewBox="0 0 512 512"
  >
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="path1948_00000153663380415884157280000008978240784360615575_"
      x1="896.959"
      x2="1393.622"
      y1="479.369"
      y2="52.058"
      gradientTransform="matrix(.563 0 0 -.568 -514.163 286.842)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#5a9fd4"></stop>
      <stop offset="1" stopColor="#306998"></stop>
    </linearGradient>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="path1950_00000075154291388394646000000000998535923326963881_"
      x1="1585.627"
      x2="1408.278"
      y1="-206.531"
      y2="44.386"
      gradientTransform="matrix(.563 0 0 -.568 -514.163 286.842)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#ffd43b"></stop>
      <stop offset="1" stopColor="#ffe873"></stop>
    </linearGradient>
    <g xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill="url(#path1948_00000153663380415884157280000008978240784360615575_)"
          d="M252.985.004c-20.882.097-40.823 1.878-58.369 4.983-51.689 9.132-61.074 28.245-61.074 63.494v46.553h122.147v15.517H87.701c-35.499 0-66.584 21.337-76.306 61.928-11.215 46.526-11.712 75.56 0 124.141 8.683 36.161 29.418 61.927 64.917 61.927h41.997V322.74c0-40.317 34.883-75.879 76.307-75.879h122.005c33.962 0 61.073-27.963 61.073-62.07V68.481c0-33.102-27.926-57.969-61.073-63.494-20.983-3.493-42.755-5.08-63.636-4.983zm-66.057 37.442c12.617 0 22.92 10.472 22.92 23.347 0 12.83-10.303 23.205-22.92 23.205-12.662 0-22.92-10.375-22.92-23.205 0-12.876 10.258-23.347 22.92-23.347z"
          data-original="url(#path1948_00000153663380415884157280000008978240784360615575_)"
        ></path>
        <path
          fill="url(#path1950_00000075154291388394646000000000998535923326963881_)"
          d="M392.927 130.551v54.24c0 42.052-35.652 77.445-76.306 77.445H194.616c-33.419 0-61.074 28.602-61.074 62.07v116.31c0 33.102 28.785 52.573 61.074 62.07 38.665 11.369 75.743 13.424 122.005 0 30.751-8.903 61.073-26.821 61.073-62.07v-46.553H255.689v-15.518h183.079c35.499 0 48.728-24.761 61.074-61.927 12.753-38.262 12.21-75.057 0-124.141-8.773-35.34-25.529-61.928-61.074-61.928h-45.841zm-68.618 294.548c12.662 0 22.92 10.375 22.92 23.205 0 12.876-10.258 23.348-22.92 23.348-12.617 0-22.92-10.472-22.92-23.348-.001-12.83 10.302-23.205 22.92-23.205z"
          data-original="url(#path1950_00000075154291388394646000000000998535923326963881_)"
        ></path>
      </g>
    </g>
  </svg>
  )
}
