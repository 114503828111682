import React from "react";

export default function MyName() {
  return (
    <div className="ml-6 md:ml-0   flex items-center  ">
      <h1 className="font-poppins font-bold  text-xl text-purpel    dark:text-white ">
        Abderrahmane
      </h1>
      <span className="bg-silver ml-1 w-2 h-2 rounded-full mt-1"></span>
    </div>
  );
}
