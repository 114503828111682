import React from 'react'

export default function PhpIcon({ cls }) {
    return (
        <svg
            className={cls}
            xmlns="http://www.w3.org/2000/svg"

            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#8f9ed1"
                d="M512 256c0 15.485-1.379 30.647-4.012 45.369C486.578 421.115 381.9 512 256 512c-94.856 0-177.664-51.587-221.884-128.24a254.005 254.005 0 01-25.088-60.155C3.135 302.07 0 279.395 0 256 0 114.615 114.615 0 256 0c116.694 0 215.144 78.075 245.979 184.842C508.5 207.433 512 231.309 512 256z"
                data-original="#8f9ed1"
            ></path>
            <g xmlns="http://www.w3.org/2000/svg" fill="#f2f2f2">
                <path
                    d="M130.173 178.239H35.892L9.028 323.605a254.005 254.005 0 0025.088 60.155h8.746l10.407-56.299h51.806c63.08 0 80.039-56.633 84.104-84.449 4.075-27.805-16.269-64.773-59.006-64.773zm13.678 69.464c-2.309 15.768-13.96 47.877-49.716 47.877H59.162l15.632-84.605h35.6c34.701 0 35.766 20.961 33.457 36.728zM501.979 184.842c-8.014-4.138-17.565-6.604-28.599-6.604h-94.281L341.117 383.76h44.951l10.407-56.299h51.806c28.056 0 46.989-11.201 59.705-26.091A257.373 257.373 0 00512 256c0-24.691-3.5-48.567-10.021-71.158zm-14.921 62.861c-2.309 15.768-13.96 47.877-49.727 47.877h-34.962l15.632-84.605h35.6c34.701 0 35.766 20.961 33.457 36.728zM309.238 178.919h-54.597l10.248-55.451h-44.766L182.14 328.984h44.766l21.843-118.186h28.61c18.991 0 31.879 4.07 29.165 21.705-2.713 17.635-18.313 95.636-18.313 95.636h45.444s17.635-86.818 20.348-111.237c2.714-24.418-19.669-37.983-44.765-37.983z"
                    data-original="#f2f2f2"
                ></path>
            </g>
        </svg>
    )
}
