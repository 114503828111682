import React from 'react';

const JavaIcon = ({cls}) => {
    return (
        <svg
            className={cls}
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 511.998 511.998"
        >
            <g xmlns="http://www.w3.org/2000/svg" fill="#db380e">
                <path
                    d="M253.464 94.869c-23.658 16.639-50.471 35.498-64.838 66.699-24.954 54.435 51.062 113.812 54.311 116.313a4.194 4.194 0 005.253-.108 4.198 4.198 0 001.031-5.152c-.275-.53-27.561-53.53-26.547-91.552.359-13.243 18.892-28.266 38.512-44.171 17.97-14.568 38.34-31.079 50.258-50.394 26.164-42.516-2.916-84.322-3.213-84.74a4.2 4.2 0 00-5.11-1.41 4.2 4.2 0 00-2.414 4.72c.059.277 5.714 27.923-10.022 56.406-6.482 11.77-20.726 21.788-37.221 33.389z"
                    data-original="#db380e"
                ></path>
                <path
                    d="M353.137 113.617a4.197 4.197 0 00-4.015-7.275c-4.019 1.528-98.416 37.915-98.416 81.88 0 30.307 12.946 46.317 22.399 58.009 3.708 4.586 6.909 8.546 7.964 11.927 2.97 9.743-4.066 27.353-7.025 33.317a4.196 4.196 0 006.155 5.313c1.623-1.128 39.667-28.026 32.844-60.433-2.542-12.318-8.595-21.318-13.936-29.26-8.274-12.305-14.25-21.193-5.184-37.609 10.622-19.148 58.727-55.505 59.214-55.869z"
                    data-original="#db380e"
                ></path>
            </g>
            <g xmlns="http://www.w3.org/2000/svg" fill="#73a1fb">
                <path
                    d="M107.418 298.236c-1.618 4.845-.854 9.651 2.207 13.903 10.233 14.207 46.48 22.029 102.068 22.029h.007c7.532 0 15.484-.148 23.629-.44 88.875-3.181 121.839-30.869 123.199-32.046a4.197 4.197 0 00-3.851-7.219c-31.301 8.546-89.748 11.58-130.288 11.58-45.363 0-68.465-3.268-74.121-5.681 2.902-3.985 20.802-11.101 42.983-15.464a4.197 4.197 0 00-.81-8.314c-13.002 0-78.207 1.044-85.023 21.652zM404.812 269.718c-18.331 0-35.714 9.188-36.446 9.577a4.199 4.199 0 001.977 7.898c.389 0 39.102.317 42.608 22.436 3.106 19.082-36.629 50-52.202 60.304a4.197 4.197 0 003.19 7.604c3.696-.791 90.406-19.899 81.238-70.384-5.593-30.94-25.039-37.435-40.365-37.435zM345.347 363.755a4.2 4.2 0 00-1.717-4.207l-20.791-14.563a4.21 4.21 0 00-3.485-.618c-.217.055-21.959 5.771-53.525 9.276-12.528 1.405-26.56 2.147-40.582 2.147-31.558 0-52.192-3.708-55.197-6.428-.398-.764-.272-1.111-.201-1.304.546-1.518 3.472-3.322 5.358-4.036a4.196 4.196 0 00-2.441-8.016c-20.856 5.033-31.054 12.071-30.312 20.918 1.318 15.686 37.65 23.737 68.365 25.865 4.417.302 9.194.455 14.195.455h.008c51.074 0 116.55-16.025 117.204-16.188a4.185 4.185 0 003.121-3.301zM188.602 397.419a4.192 4.192 0 001.714-4.764c-.557-1.793-2.234-2.971-4.118-2.946-2.795.074-27.349 1.182-29.068 16.815-.52 4.672.818 8.941 3.979 12.686 8.816 10.448 32.614 16.658 72.741 18.984 4.747.285 9.569.428 14.334.428 51.015 0 85.373-15.973 86.812-16.653a4.197 4.197 0 00.394-7.377l-26.275-16.04a4.224 4.224 0 00-3.043-.527c-.166.035-16.849 3.495-42.026 6.913-4.764.648-10.73.977-17.73.977-25.15 0-53.124-4.109-58.489-6.8-.078-.502.021-1.14.775-1.696z"
                    data-original="#73a1fb"
                ></path>
                <path
                    d="M224.408 486.85c116.854-.099 179.571-20.88 191.653-33.957 4.277-4.626 4.739-9.006 4.376-11.867-.898-7.04-7.311-11.35-8.038-11.818-1.754-1.128-4.108-.833-5.476.745-1.365 1.578-1.397 3.884-.027 5.461.737.948 1.163 2.535-.992 4.692-4.83 4.511-53.545 18.204-134.656 22.318-11.111.577-22.765.871-34.636.873-72.623 0-125.772-9.948-132.749-15.744 2.689-3.864 21.489-10.037 41.482-13.529a4.196 4.196 0 00-1.283-8.295c-.564.077-2.478.191-4.694.327-32.988 2.014-71.109 6.503-73.098 23.5-.604 5.179.935 9.881 4.576 13.973 8.909 10.01 34.516 23.319 153.558 23.319l.004.002z"
                    data-original="#73a1fb"
                ></path>
                <path
                    d="M439.013 456.578a4.192 4.192 0 00-4.836.952c-.171.185-17.74 18.556-70.564 29.344-20.223 4.052-58.183 6.107-112.826 6.107-54.745 0-106.838-2.154-107.357-2.176-2.176-.106-4.037 1.476-4.333 3.618a4.197 4.197 0 003.184 4.658c.542.128 55.135 12.918 129.779 12.918 35.801 0 70.639-2.907 103.548-8.645 61.361-10.757 65.657-41.183 65.81-42.473a4.197 4.197 0 00-2.405-4.303z"
                    data-original="#73a1fb"
                ></path>
            </g>
        </svg>
    );
}

export default JavaIcon;



