import React from 'react'

export default function OracleIcon({cls}) {
  return (
    <svg
    className={cls}
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 512 512"
    viewBox="0 0 511.969 511.969"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="#c74634"
      d="M221.505 265.971h33.766l-17.835-28.803-32.791 52.022h-14.911l39.881-62.413c3.005-4.304 8.93-5.358 13.234-2.353a9.52 9.52 0 012.275 2.243l39.991 62.524h-14.911l-7.023-11.632h-34.165l-7.444-11.587m154.936 11.587v-54.216h-12.673v59.555a6.114 6.114 0 001.883 4.431 6.402 6.402 0 004.608 1.927h57.605l7.511-11.632h-59.001m-209.462-9.747c12.298 0 22.267-9.969 22.267-22.267s-9.969-22.267-22.267-22.267h-55.39v65.847h12.651v-54.193h41.963c5.886 0 10.657 4.771 10.657 10.657s-4.771 10.657-10.657 10.657H130.51l37.798 32.879h18.367l-25.391-21.314h5.695M33.778 289.191h38.263c18.177-.472 32.53-15.59 32.059-33.767-.454-17.514-14.545-31.604-32.059-32.059H33.778C15.601 222.893.483 237.246.011 255.424c-.472 18.177 13.881 33.295 32.059 33.767.569.014 1.139.014 1.708 0m37.399-11.588H34.62c-11.765-.38-20.995-10.225-20.616-21.99.362-11.232 9.383-20.254 20.616-20.616h36.557c11.765-.38 21.611 8.85 21.99 20.616.38 11.765-8.851 21.61-20.616 21.99-.458.015-.916.015-1.374 0m240.37 11.588h39.061l7.4-11.588h-45.597c-11.765.379-21.61-8.85-21.99-20.616-.379-11.765 8.85-21.611 20.616-21.99.458-.015.916-.015 1.374 0h37.111l7.489-11.632h-45.464c-18.177-.472-33.295 13.881-33.767 32.059-.472 18.177 13.881 33.295 32.059 33.767.569.014 1.139.014 1.708 0m154.803-11.588a21.313 21.313 0 01-20.516-15.509h54.105l7.444-11.61h-61.549a21.291 21.291 0 0120.516-15.509h37.133l7.422-11.632h-45.42c-18.177.472-32.53 15.59-32.059 33.767.454 17.514 14.545 31.604 32.059 32.059h39.061l7.422-11.587h-45.641"
      data-original="#c74634"
    ></path>
  </svg>
  )
}
